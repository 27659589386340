import request from '@/utils/request'
// 提交idfa 和通讯录
function addressBook () {
  const contant = getContactsFromStorage()
  const idfa = localStorage.getItem('idfa') || ''
  if (contant || idfa) {
    const contantList = JSON.stringify(contant) || JSON.stringify([])
    request.post('addressBook', { contantList, idfa: idfa.replace(/"/g, '') })
  }
}

// 兼容多种通讯录储存到localStorage的key
function getContactsFromStorage () {
  const keyList = [
    {
      contactKey: 'contact',
      name: 'accountName',
      phone: 'accountPhone'
    },
    {
      contactKey: 'jfriou',
      name: 'whufih',
      phone: 'qmfrojh'
    }
  ]
  let contant = ''
  // 使用some方法，当找到有值的key时会自动终止循环
  keyList.some(key => {
    const valueJSON = localStorage.getItem(key.contactKey)
    if (valueJSON) {
      const valueArray = JSON.parse(valueJSON)
      contant = valueArray.map(item => ({
        accountName: item[key.name],
        accountPhone: item[key.phone]
      }))
      return true
    }
    return false
  })
  return contant
}
export default addressBook
